import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import InputField from "../../../components/common/inputfield/InputField";
import Selector from "../../../components/common/selects/Selector";
import { addJobs, getUsersAll } from "../../../redux/Action";

const AddJob = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allUserList } = useSelector((state) => state?.main);
  const storeList = allUserList?.data?.stores || [];
  const ShiftList = allUserList?.data?.shift || [];
  const storeUsers = storeList.map((user) => ({
    label: user.name,
    value: user._id,
  }));
  const shifbytList = ShiftList.map((user) => ({
    label: user.name,
    value: user._id,
  }));

  useEffect(() => {
    dispatch(getUsersAll());
  }, []);
  const optionsedu = [
    { value: "high_school", label: "High School" },
    { value: "diploma", label: "Diploma" },
    { value: "bachelor", label: "Bachelor's Degree" },
    { value: "master", label: "Master's Degree" },
    { value: "phd", label: "Doctorate (PhD)" },
    { value: "other", label: "Other" },
  ];
  const optionlanguage = [
    { value: "en", label: "English" },
    { value: "hi", label: "Hindi" },
    { value: "es", label: "Spanish" },
    { value: "fr", label: "French" },
    { value: "de", label: "German" },
    { value: "zh", label: "Chinese" },
    { value: "ja", label: "Japanese" },
    { value: "ru", label: "Russian" },
    { value: "ar", label: "Arabic" },
    { value: "pt", label: "Portuguese" },
  ];
  const optionsjobexperience = [
    { value: "0-1", label: "0-1 Years (Fresher)" },
    { value: "1-3", label: "1-3 Years" },
    { value: "3-5", label: "3-5 Years" },
    { value: "5-10", label: "5-10 Years" },
    { value: "10-15", label: "10-15 Years" },
    { value: "15+", label: "15+ Years" },
  ];
  const optionsjobtime = [
    { value: "0-1", label: "0-1 Years (Fresher)" },
    { value: "1-3", label: "1-3 Years" },
    { value: "3-5", label: "3-5 Years" },
    { value: "5-10", label: "5-10 Years" },
    { value: "10-15", label: "10-15 Years" },
    { value: "15+", label: "15+ Years" },
  ];
  return (
    <div>
      <div className="container mx-auto mt-4 mb-4 ">
        <Formik
          initialValues={{
            jobemail: "",
            jobtitle: "",
            jobbio: "",
            jobskill: "",
            jobrequirement: "",
            jobstore: "",
            jobsalary: "",
            jobrole: "",
            jobtime: "",
            jobshift: "",
            jobedulevel: "",
            joblanguage: "",
            jobexperience: "",
            jobmobile: "",
          }}
          validate={(values, files, name) => {
            const errors = {};
            if (!values.jobtitle) {
              errors.jobtitle = "Job title is required";
            }
            if (!values.jobbio) {
              errors.jobbio = "Job bio is required";
            }
            if (!values.jobskill) {
              errors.jobskill = "Job skill set is required";
            }
            if (!values.jobrequirement) {
              errors.jobrequirement = "Job requirement is required";
            }
            if (!values.jobstore) {
              errors.jobstore = "Store selection is required";
            }
            if (!values.jobsalary) {
              errors.jobsalary = "Salary is required";
            }
            if (!values.jobrole) {
              errors.jobrole = "Job role is required";
            }
            if (!values.jobtime) {
              errors.jobtime = "Job time is required";
            }
            if (!values.jobshift) {
              errors.jobshift = "Job shift is required";
            }
            if (!values.jobedulevel) {
              errors.jobedulevel = "Education level is required";
            }
            if (!values.joblanguage) {
              errors.joblanguage = "Language is required";
            }
            if (!values.jobexperience) {
              errors.jobexperience = "Experience is required";
            }
            if (!values.jobmobile) {
              errors.jobmobile = "Mobile number is required";
            }
            if (!values.jobemail) {
              errors.jobemail = "Email is required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.jobemail)
            ) {
              errors.jobemail = "Invalid email address";
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            const userData = new FormData();
            userData.append("title", values?.jobtitle);
            userData.append("bio", values?.jobbio);
            userData.append("email", values?.jobemail);
            userData.append("skill", values?.jobskill);
            userData.append("requirement", values?.jobrequirement);
            userData.append("storeName", values?.jobstore);
            userData.append("storeId", values?.jobstore);
            userData.append("salaryRange", values?.jobsalary);
            userData.append("role", values?.jobrole);
            userData.append("type", values?.jobtime);
            userData.append("shiftName", values?.jobshift);
            userData.append("shiftId", values?.jobshift);
            userData.append("educationLevel", values?.jobedulevel);
            userData.append("language", values?.joblanguage);
            userData.append("experiene", values?.jobexperience);
            userData.append("mobile", values?.jobmobile);

            dispatch(addJobs(userData, navigate));
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => {
            return (
              <>
                <div className="container mx-auto mt-4 mb-4 ">
                  <form onSubmit={handleSubmit}>
                    <>
                      {/* <div className="top_bottomEmployee">
                    <h4>Add Employee</h4>
                  </div>
                  <div className="info_btm">
                    <span>Employee Information</span>
                  </div> */}
                      <div className="flex_input">
                        <div className="row_col">
                          <InputField
                            label="Job Title"
                            inputType="text"
                            inputPlaceholder="Enter title"
                            InputFontSize="16px"
                            LabelFontSize="16px"
                            name="jobtitle"
                            value={values.jobtitle}
                            onChange={handleChange}
                            width="23%"
                          />

                          {errors.jobtitle && touched.jobtitle && (
                            <div className="error-message">
                              {errors.jobtitle}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="flex_input">
                        <div className="row_col">
                          <InputField
                            label="Job Bio"
                            inputType="text"
                            inputPlaceholder="Enter Job Bio"
                            InputFontSize="16px"
                            LabelFontSize="16px"
                            name="jobbio"
                            value={values.jobbio}
                            onChange={handleChange}
                          />

                          {errors.jobbio && touched.jobbio && (
                            <div className="error-message">{errors.jobbio}</div>
                          )}
                        </div>
                        <div className="row_col">
                          <InputField
                            label="Job Role"
                            inputType="text"
                            inputPlaceholder="Enter Job Role"
                            InputFontSize="16px"
                            LabelFontSize="16px"
                            name="jobrole"
                            value={values.jobrole}
                            onChange={handleChange}
                          />

                          {errors.jobrole && touched.jobrole && (
                            <div className="error-message">
                              {errors.jobrole}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="flex_input">
                        <div className="row_col">
                          <InputField
                            label="Job Requirement and Qualification"
                            inputType="text"
                            inputPlaceholder="Job Requirement and Qualification"
                            InputFontSize="16px"
                            LabelFontSize="16px"
                            name="jobrequirement"
                            value={values.jobrequirement}
                            onChange={handleChange}
                          />

                          {errors.jobrequirement && touched.jobrequirement && (
                            <div className="error-message">
                              {errors.jobrequirement}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="flex_input">
                        <div className="row_col">
                          <InputField
                            label="Job Skill Set"
                            inputType="text"
                            inputPlaceholder="Job Skill Set"
                            InputFontSize="16px"
                            LabelFontSize="16px"
                            name="jobskill"
                            value={values.jobskill}
                            onChange={handleChange}
                          />

                          {errors.jobskill && touched.jobskill && (
                            <div className="error-message">
                              {errors.jobskill}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="flex_input">
                        <div className="row_col">
                          <Selector
                            label="Store"
                            options={storeUsers}
                            name="jobstore "
                            value={values.jobstore}
                            onChange={(selectedOption) => {
                              setFieldValue(
                                "jobstore",
                                selectedOption?.target.value?.value
                              );
                              setFieldValue(
                                "storeId",
                                selectedOption?.target.value?.value
                              );
                            }}
                            LabelFontSize="16px"
                            SelectorFontSize="16"
                          />

                          {errors.jobstore && touched.jobstore && (
                            <div className="error-message">
                              {errors.jobstore}
                            </div>
                          )}
                        </div>
                        <div className="row_col">
                          <InputField
                            label="Salary"
                            inputType="text"
                            inputPlaceholder="Salary"
                            InputFontSize="16px"
                            LabelFontSize="16px"
                            name="jobsalary"
                            value={values.jobsalary}
                            onChange={handleChange}
                          />

                          {errors.jobsalary && touched.jobsalary && (
                            <div className="error-message">
                              {errors.jobsalary}
                            </div>
                          )}
                        </div>
                        <div className="row_col">
                          <Selector
                            label="Time"
                            options={optionsjobtime}
                            name="jobtime"
                            value={values.jobtime}
                            onChange={(e) => {
                              setFieldValue("jobtime", e?.target.value?.value);
                            }}
                            LabelFontSize="16px"
                            SelectorFontSize="16"
                          />

                          {errors.jobtime && touched.jobtime && (
                            <div className="error-message">
                              {errors.jobtime}
                            </div>
                          )}
                        </div>
                        <div className="row_col">
                          <Selector
                            label="Shift"
                            options={shifbytList}
                            name="jobshift"
                            value={values.jobshift}
                            onChange={(selectedOption) => {
                              setFieldValue(
                                "jobshift",
                                selectedOption?.target.value?.value
                              );
                              setFieldValue(
                                "shiftId",
                                selectedOption?.target.value?.value
                              );
                            }}
                            LabelFontSize="16px"
                            SelectorFontSize="16"
                          />

                          {errors.jobshift && touched.jobshift && (
                            <div className="error-message">
                              {errors.jobshift}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex_input">
                        <div className="row_col">
                          <Selector
                            label="Education Level"
                            options={optionsedu}
                            name="jobedulevel"
                            value={values.jobedulevel}
                            onChange={(e) =>
                              setFieldValue(
                                "jobedulevel",
                                e.target.value?.value
                              )
                            }
                            LabelFontSize="16px"
                            SelectorFontSize="16"
                          />

                          {errors.jobedulevel && touched.jobedulevel && (
                            <div className="error-message">
                              {errors.jobedulevel}
                            </div>
                          )}
                        </div>
                        <div className="row_col">
                          <Selector
                            label="Language"
                            options={optionlanguage}
                            name="joblanguage"
                            value={values.joblanguage}
                            onChange={(e) =>
                              setFieldValue(
                                "joblanguage",
                                e.target.value?.value
                              )
                            }
                            LabelFontSize="16px"
                            SelectorFontSize="16"
                          />

                          {errors.joblanguage && touched.joblanguage && (
                            <div className="error-message">
                              {errors.joblanguage}
                            </div>
                          )}
                        </div>{" "}
                        <div className="row_col">
                          <Selector
                            label="Experience"
                            options={optionsjobexperience}
                            name="jobexperience"
                            value={values.jobexperience}
                            onChange={(e) =>
                              setFieldValue(
                                "jobexperience",
                                e.target.value?.value
                              )
                            }
                            LabelFontSize="16px"
                            SelectorFontSize="16"
                          />

                          {errors.jobexperience && touched.jobexperience && (
                            <div className="error-message">
                              {errors.jobexperience}
                            </div>
                          )}
                        </div>
                        <div className="row_col">
                          <InputField
                            label="Mobile"
                            inputType="tel"
                            inputPlaceholder="Enter Mobile"
                            InputFontSize="16px"
                            LabelFontSize="16px"
                            name="jobmobile"
                            value={values.jobmobile}
                            onChange={handleChange}
                          />

                          {errors.jobmobile && touched.jobmobile && (
                            <div className="error-message">
                              {errors.jobmobile}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex_input">
                        <div className="row_col">
                          <InputField
                            label="Email"
                            inputType="text"
                            inputPlaceholder="Enter Email"
                            InputFontSize="16px"
                            LabelFontSize="16px"
                            name="jobemail"
                            value={values.jobemail}
                            onChange={handleChange}
                            width="23%"
                          />

                          {errors.jobemail && touched.jobemail && (
                            <div className="error-message">
                              {errors.jobemail}
                            </div>
                          )}
                        </div>
                      </div>
                    </>

                    <div className="btn_job">
                      <button type="submit" disabled={isSubmitting}>
                        Add Job
                      </button>
                    </div>
                  </form>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddJob;

import {
  SET_ADD_USERDATA,
  SET_BTN_LOADING,
  SET_DETELE_USER,
  SET_GET_ALL_USERS,
  SET_LOADING,
  SET_LOGIN,
  SET_LOGOUT,
  SET_USERLIST,
  SET_LOCALSTRORAGE_LOGIN,
  SET_REFRESHER_LOGIN,
  SET_LEAVEDATA,
  SET_GRIEVANCEDATA,
  SET_UPDATE_USERDATA,
  SET_VIEW_USERDATA,
  SET_ALLUSERLIST,
  SET_LEAVEDATAREJAPP,
  SET_LEAVEDELATAILSDATA,
  SET_RESIGNATIONDATA,
  SET_GRIEVANCEDETAILSDATA,
  SET_RESIGNATIONDETAILSDATA,
  SET_GRIEVANCEREJAPP,
  SET_POSHDETAILSDATA,
  SET_POSHDATA,
  SET_JOBLIST,
  SET_JOBDETAILS,
  SET_ADD_JOBDATA,
  SET_REPORTLIST,
  SET_STORELIST,
  SET_STORESINGLE,
  SET_STORESINGLEUSER,
  SET_REPORTSINGLEUSER,
  SET_DASHBOARDLIST,
  SET_GRIEVANCECOMMEENT,
  SET_POSHCOMMEENT,
  SET_RESIGNATIONCOMMEENT,
  SET_LEAVECOMMEENT,
  SET_ALLCOMPANYLIST,
  SET_ADD_COMPANY,
  SET_ALLSHIFTLIST,
  SET_ADD_SHIFT,
  SET_ALLSTORELIST,
  SET_ADD_STORE,
  SET_ALLROLELIST,
  SET_ADD_ROLE,
  SET_ALLRESIGNATIONLIST,
  SET_ADD_RESINATION,
  SET_ADD_STORES,
  SET_ALL_STORE_LIST_DATA,
  SET_USER_ATTENDENCE_DATA,
  SET_DETELE_JOB,
  SET_DETELE_COMPANY,
  SET_DETELE_STORE,
  SET_DETELE_ROLE,
  SET_DETELE_DESIGNATION,
  SET_DETELE_SHIFT,
  SET_COUNTRY_LIST,
  SET_SITE_SETTING,
  SET_STATE_LIST,
  SET_CITY_LIST,
  SET_SEARCH_USER,
  SET_ADD_DOCUMENTUPLOAD,
  SET_GET_DOCUMENTUPLOAD,
  SET_DESIGNATION_UPDATE,
  SET_STORESINGLE_USER,
} from "../types";

const initialState = {
  isAuthenticated: !localStorage.getItem("token"),
  loading: false,
};

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN:
      return {
        ...state,
        isAuthenticated: true,
      };
    case SET_LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
      };

    case SET_USERLIST:
      return {
        ...state,
        getAllUserList: action.payload,
      };
    case SET_ADD_USERDATA:
      return {
        ...state,
        addUserList: action.payload,
      };
    case SET_UPDATE_USERDATA:
      return {
        ...state,
        updateUserList: action.payload,
      };
    case SET_VIEW_USERDATA:
      return {
        ...state,
        viewUserList: action.payload,
      };
    case SET_ADD_STORES:
      return {
        ...state,
        myAllStoreList: action.payload,
      };
    case SET_ALL_STORE_LIST_DATA:
      return {
        ...state,
        myAllStoreListData: action.payload,
      };
    case SET_DETELE_USER:
      return {
        ...state,
        deleteUser: action.payload,
      };
    case SET_LOCALSTRORAGE_LOGIN:
      return {
        ...state,
        addlocalstorageaccesstoken: action.payload,
      };

    case SET_REFRESHER_LOGIN:
      return {
        ...state,
        addlocalstoragerefreshtoken: action.payload,
      };
    case SET_LEAVEDATA:
      return {
        ...state,
        getLeaveList: action.payload,
      };
    case SET_GRIEVANCEDATA:
      return {
        ...state,
        getGrievanceList: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_ALLUSERLIST:
      return {
        ...state,
        allUserList: action.payload,
      };
    case SET_LEAVEDATAREJAPP:
      return {
        ...state,
        allAppRejLeaveList: action.payload,
      };
    case SET_LEAVEDELATAILSDATA:
      return {
        ...state,
        allDetailLeave: action.payload,
      };
    case SET_RESIGNATIONDATA:
      return {
        ...state,
        allResignationList: action.payload,
      };

    case SET_RESIGNATIONDETAILSDATA:
      return {
        ...state,
        allResignationDetailsList: action.payload,
      };
    case SET_GRIEVANCEDETAILSDATA:
      return {
        ...state,
        allGrievanceDetailList: action.payload,
      };
    case SET_GRIEVANCEREJAPP:
      return {
        ...state,
        allGrievanceRejApp: action.payload,
      };
    case SET_POSHDATA:
      return {
        ...state,
        allposhList: action.payload,
      };
    case SET_POSHDETAILSDATA:
      return {
        ...state,
        allPoshDetailList: action.payload,
      };
    case SET_JOBLIST:
      return {
        ...state,
        allJobList: action.payload,
      };
    case SET_JOBDETAILS:
      return {
        ...state,
        allJobDetailList: action.payload,
      };
    case SET_ADD_JOBDATA:
      return {
        ...state,
        alladdJob: action.payload,
      };
    case SET_REPORTLIST:
      return {
        ...state,
        allReportList: action.payload,
      };
    case SET_STORELIST:
      return {
        ...state,
        allStoreList: action.payload,
      };
    case SET_STORESINGLE:
      return {
        ...state,
        allStoreSingleDetail: action.payload,
      };
    case SET_STORESINGLE_USER:
      return {
        ...state,
        allStoreSingleUser: action.payload,
      };
    case SET_USER_ATTENDENCE_DATA:
      return {
        ...state,
        userFullAttendenceData: action.payload,
      };
    case SET_REPORTSINGLEUSER:
      return {
        ...state,
        allReportSingleUser: action.payload,
      };
    case SET_DASHBOARDLIST:
      return {
        ...state,
        alldashboardList: action.payload,
      };
    case SET_GRIEVANCECOMMEENT:
      return {
        ...state,
        allgrievancelist: action.payload,
      };
    case SET_RESIGNATIONCOMMEENT:
      return {
        ...state,
        allResignationlist: action.payload,
      };
    case SET_POSHCOMMEENT:
      return {
        ...state,
        allPoshlist: action.payload,
      };
    case SET_LEAVECOMMEENT:
      return {
        ...state,
        allLeavelist: action.payload,
      };
    case SET_ALLCOMPANYLIST:
      return {
        ...state,
        allCompanylist: action.payload,
      };
    case SET_ADD_COMPANY:
      return {
        ...state,
        addCompanyUser: action.payload,
      };
    case SET_ALLSHIFTLIST:
      return {
        ...state,
        allShiftList: action.payload,
      };
    case SET_COUNTRY_LIST:
      return {
        ...state,
        allCountryList: action.payload,
      };
    case SET_SITE_SETTING:
      return {
        ...state,
        siteSettingDetails: action.payload,
      };
    case SET_STATE_LIST:
      return {
        ...state,
        getAllStateData: action.payload,
      };
    case SET_CITY_LIST:
      return {
        ...state,
        getAllCityData: action.payload,
      };
    case SET_ADD_SHIFT:
      return {
        ...state,
        addShiftUser: action.payload,
      };
    case SET_ALLSTORELIST:
      return {
        ...state,
        allStoreLists: action.payload,
      };
    case SET_ADD_STORE:
      return {
        ...state,
        addStoreUser: action.payload,
      };
    case SET_ALLROLELIST:
      return {
        ...state,
        allRoleList: action.payload,
      };
    case SET_ADD_ROLE:
      return {
        ...state,
        addRoleList: action.payload,
      };
    case SET_ALLRESIGNATIONLIST:
      return {
        ...state,
        allResignationList: action.payload,
      };
    case SET_ADD_RESINATION:
      return {
        ...state,
        allResignationList: action.payload,
      };
    case SET_DETELE_JOB:
      return {
        ...state,
        deleteJoblist: action.payload,
      };
    case SET_DETELE_COMPANY:
      return {
        ...state,
        deleteCompanylist: action.payload,
      };
    case SET_DETELE_STORE:
      return {
        ...state,
        deleteStorelist: action.payload,
      };
    case SET_DETELE_DESIGNATION:
      return {
        ...state,
        deleteDesignationlist: action.payload,
      };
    case SET_DETELE_ROLE:
      return {
        ...state,
        deleteRolelist: action.payload,
      };
    case SET_DETELE_SHIFT:
      return {
        ...state,
        deleteShiftlist: action.payload,
      };
    case SET_SEARCH_USER:
      return {
        ...state,
        allusersearch: action.payload,
      };
    case SET_ADD_DOCUMENTUPLOAD:
      return {
        ...state,
        userDocumentUpload: action.payload,
      };
    case SET_GET_DOCUMENTUPLOAD:
      return {
        ...state,
        allDocumentUpload: action.payload,
      };
    case SET_DESIGNATION_UPDATE:
      return {
        ...state,
        addDesignation: action.payload,
      };
    default:
      return state;
  }
};

export default mainReducer;

import React, { useState } from "react";
import "./adddesgination.css"; // Assuming you have CSS to style the form
import { VscClose } from "react-icons/vsc";
import { addSettingResignation } from "../../redux/Action";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const AddDesgination = ({ onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allResignationList } = useSelector((state) => state.main);

  const fields = [
    { label: "Designation Name", type: "text", id: "name" },
    {
      label: "Reporting Designation",
      type: "select",
      id: allResignationList?.data?._id,
      options: allResignationList?.data,
    },
  ];

  const [formData, setFormData] = useState({
    name: "",
    designation_id: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const clickSubmitHandler = () => {
    const payload = new FormData();

    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null) {
        if (formData[key] instanceof File) {
          payload.append(key, formData[key]);
        } else {
          payload.append(key, formData[key]);
        }
      }
    });

    dispatch(addSettingResignation(payload, navigate));
  };

  return (
    // <div className="container">
    <>
      <div className="adddesignation">
        <div className="d-flex justify-content-between">
          <h2>Add New Desgination</h2>
          <VscClose size={20} onClick={onClose} />
        </div>
      </div>
      <div className="border-bottom"></div>
      <div
        className="d-flex justify-content-between gap-1 content_common"
        style={{ borderBottom: "2px solid #2C357D2E" }}
      >
        {" "}
        <div className="label_name">
          {fields?.map((field) => (
            <label key={field.id} htmlFor={field.id}>
              {field.label}
            </label>
          ))}
        </div>
        <div className="input_group mx-auto">
          {fields?.map((field) => (
            <div key={field.id}>
              {field?.type === "select" ? (
                <select
                  // id={field.id}
                  // value={formData[field.id]}
                  onChange={(e) => handleChange(e)}
                  className="form-group"
                  id="designation_id"
                >
                  <option hidden>Select designation</option>
                  {field?.options?.map((option, index) => (
                    <option key={index} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              ) : field.type === "file" ? (
                <>
                  <div className="d-flex ">
                    <input
                      type="file"
                      id={field.id}
                      accept="image/*"
                      onChange={handleChange}
                      className="form-group"
                    />
                  </div>
                </>
              ) : (
                <input
                  className="form-group"
                  type={field.type}
                  id={field.id}
                  value={formData[field.id]}
                  onChange={handleChange}
                  placeholder={`Enter ${field.label}`}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="border-bottom"></div>
      <div className="btnblue px-4 pb-5 mt-1">
        <button onClick={clickSubmitHandler}>Submit</button>
      </div>
    </>
    // </div>
  );
};

export default AddDesgination;

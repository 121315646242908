import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./tablesection.css";
import moment from "moment";
import downdrop from "../../../assets/img/downdrop.svg";
import { userDeleteData } from "../../../redux/Action";
import ViewUser from "../../../Admin/pages/Users/ViewUser";
import EditUser from "../../../Admin/pages/Users/EditUser";
import greydrop from "../../../assets/img/greydrop.png";
import { calcLength } from "framer-motion";
import Delete from "../../../Common/Delete/Delete";
import Pagination from "../../../Common/Pagination/Pagination";
import SpinnerLoader from "../spinnerloader/SpinnerLoader";

const TableSection = ({
  tableData,
  columns,
  getAllUserList,
  setCurrentPage,
  currentPage,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageLoading = useSelector((state) => state.main.loading);

  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();

  const userRole = localStorage.getItem("role");

  const onCloseModal = () => setOpen(false);

  const onOpenModal = (data) => {
    setSelectedUserId(data);
    setOpen(true);
  };

  const handleDelete = () => {
    dispatch(userDeleteData(selectedUserId?._id));
    onCloseModal();
  };

  const [onRequest, setOnRequest] = useState(false);
  const [onEdit, setOnEdit] = useState(false);

  const OpenRequest = (data) => {
    setOnRequest(true);
    setSelectedUserId(data);
    navigate(`/user/viewuser/${data}`);
  };
  const OpenEdit = (data) => {
    setOnEdit(true);
    setSelectedUserId(data);
    navigate(`/user/edituser/${data}`);
  };

  return (
    <>
      {pageLoading ? (
        <SpinnerLoader />
      ) : (
        <table id="example" className="table">
          <thead>
            <tr>
              {columns?.map((column, index) => (
                <th key={index} className="table-header">
                  {column}
                  {[
                    "Store",
                    "Role",
                    "Shift Time",
                    "Slot time",
                    "Status",
                  ].includes(column) && (
                    <img
                      src={greydrop}
                      alt="dropdown icon"
                      className="header-dropdown-icon"
                      onClick={() =>
                        console.log(`Dropdown clicked for ${column}`)
                      }
                      style={{ marginLeft: "8px" }}
                    />
                  )}
                </th>
              ))}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {tableData && tableData.length === 0 ? (
              <tr>
                <td colSpan="7" className="text-center">
                  No data found
                </td>
              </tr>
            ) : (
              tableData &&
              tableData?.map((items, index) => (
                <tr key={index}>
                  {columns?.includes("No.") && <td>{index + 1}</td>}
                  {columns?.includes("Store") && <td>Store 1</td>}
                  {columns?.includes("Employee ID") && <td>{items?.emp_id}</td>}
                  {columns?.includes("Employee name ") && (
                    <td>{items?.name}</td>
                  )}
                  {columns?.includes("Role") && <td>{items?.role}</td>}
                  {columns?.includes("Shift Time") && (
                    <td>
                      {/* {items?.created_at
                      ? moment(items.created_at).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )
                      : ""} */}
                      morning
                    </td>
                  )}
                  {columns?.includes("Slot time") && (
                    <td>
                      {/* {items?.updated_at
                      ? moment(items.updated_at).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )
                      : ""} */}
                      morning
                    </td>
                  )}
                  {columns?.includes("Status") && (
                    <td>
                      <span
                        className={` ${
                          items?.status ? "btn-success" : "btn-danger"
                        }`}
                      >
                        {items?.status ? "Active" : "Inactive"}
                      </span>
                    </td>
                  )}

                  <td className="pl-4 d-flex gap-3">
                    {userRole === "admin" && (
                      <button onClick={() => OpenRequest(items._id)}>
                        View
                      </button>
                    )}
                    {userRole === "management" && (
                      <button onClick={() => OpenEdit(items._id)}>Edit</button>
                    )}
                    <button onClick={() => onOpenModal(items)}>Delete</button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      )}
      <Pagination
        listData={getAllUserList?.meta}
        setPageData={setCurrentPage}
        tableData={tableData}
      />

      <>
        {open && (
          <Delete open={open} onClose={onCloseModal} onConfirm={handleDelete} />
        )}
      </>
      {onRequest && <ViewUser userId={selectedUserId} type="viewuser" />}
      {onEdit && <EditUser userId={selectedUserId} />}
    </>
  );
};

export default TableSection;

import React, { useState } from "react";
import "./addshift.css"; // Assuming you have CSS to style the form
import { VscClose } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addSettingShift } from "../../redux/Action";

const AddShift = ({ onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fields = [
    { label: "Shift Name", type: "text", id: "name" },
    { label: "Shift Code", type: "text", id: "shift-code" },
    { label: "Shift Hour", type: "time", id: "shift-hour" },
    { label: "Shift Start Time", type: "timeAm", id: "shift-start-time" },
    { label: "Shift End Time", type: "time", id: "shift-end-time" },
    { label: "Grace In Time", type: "time", id: "grace-in-time" },
    { label: "Grace Out Time", type: "time", id: "grace-out-time" },
    { label: "Working Hour", type: "time", id: "working-hour" },
  ];

  const [formData, setFormData] = useState({
    name: "",
    "shift-code": "",
    "shift-hour": "",
    "shift-start-time-hour": "",
    "shift-start-time-minute": "",
    "shift-start-time-period": "AM",
    "shift-end-time-hour": "",
    "shift-end-time-minute": "",
    "shift-end-time-period": "AM",
    "grace-in-time-hour": "",
    "grace-in-time-minute": "",
    "grace-in-time-period": "AM",
    "grace-out-time-hour": "",
    "grace-out-time-minute": "",
    "grace-out-time-period": "AM",
    "working-hour-hour": "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const clickSubmitHandler = () => {
    const payload = new FormData();

    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null) {
        if (formData[key] instanceof File) {
          payload.append(key, formData[key]);
        } else {
          payload.append(key, formData[key]);
        }
      }
    });

    dispatch(addSettingShift(payload, navigate));
  };

  return (
    <>
      <div className="addshift">
        <div className="d-flex justify-content-between">
          <h2>Add New Shift</h2>
          <VscClose size={20} onClick={onClose} />
        </div>
      </div>
      <div className="border-bottom"></div>
      <div
        className="d-flex justify-content-between gap-1 content_common"
        style={{ borderBottom: "2px solid #2C357D2E" }}
      >
        {/* Fields for Shift Details */}
        <div className="label_name">
          {fields.map((field) => (
            <label key={field.id} htmlFor={field.id}>
              {field.label}
            </label>
          ))}
        </div>

        {/* Input Fields for Shift Details */}
        <div className="input_group mx-auto">
          {fields.map((field) => (
            <div key={field.id}>
              {field.type === "text" || field.type === "number" ? (
                <input
                  className="form-group"
                  type={field.type}
                  id={field.id}
                  value={formData[field.id]}
                  onChange={handleChange}
                  placeholder={`Enter ${field.label}`}
                />
              ) : field.type === "file" ? (
                <input
                  type="file"
                  id={field.id}
                  accept="image/*"
                  onChange={handleChange}
                  className="form-group"
                />
              ) : field.type === "timeAm" ? (
                <div className="d-flex " style={{ paddingBottom: "20px" }}>
                  {/* Hour Input */}
                  <input
                    type="text"
                    id={`${field.id}-hour`}
                    value={formData[`${field.id}-hour`]}
                    onChange={handleChange}
                    className="form-control time-input"
                    placeholder="HH"
                    min="1"
                    max="12"
                    style={{
                      borderRight: "0",
                      borderTopRightRadius: "0",
                      borderBottomRightRadius: "0",
                      width: "49%",
                      padding: " 6px 10px",
                    }}
                  />
                  <select
                    id={`${field.id}-period`}
                    value={formData[`${field.id}-period`]}
                    onChange={handleChange}
                    className="form-control time-dropdown"
                    style={{
                      borderTopLeftRadius: "0",
                      borderBottomLeftRadius: "0",
                      width: "29%",
                    }}
                  >
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                  <div style={{ padding: "5px" }}>:</div>
                  {/* Minute Input */}
                  <div
                    className="input_field"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <input
                      type="text"
                      id={`${field.id}-minute`}
                      value={formData[`${field.id}-minute`]}
                      onChange={handleChange}
                      className="form-control time-input"
                      placeholder="MM"
                      min="0"
                      max="59"
                      style={{
                        borderRight: "0",
                        borderTopRightRadius: "0",
                        borderBottomRightRadius: "0",
                        width: "64%",
                      }}
                    />
                    {/* AM/PM Selector */}
                    <select
                      id={`${field.id}-period`}
                      value={formData[`${field.id}-period`]}
                      onChange={handleChange}
                      className="form-control time-dropdown"
                      style={{
                        borderTopLeftRadius: "0",
                        borderBottomLeftRadius: "0",
                        width: "33%",
                      }}
                    >
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </div>
              ) : field.type === "time" ? (
                <div className="d-flex " style={{ paddingBottom: "20px" }}>
                  {/* Hour Input */}
                  <input
                    type="text"
                    id={`${field.id}-hour`}
                    value={formData[`${field.id}-hour`]}
                    onChange={handleChange}
                    className="form-control time-input"
                    placeholder="HH"
                    min="1"
                    max="12"
                    style={{
                      width: "150px",
                    }}
                  />
                  <div style={{ padding: "5px" }}>:</div>
                  <div
                    className="input_field"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <input
                      type="text"
                      id={`${field.id}-minute`}
                      value={formData[`${field.id}-minute`]}
                      onChange={handleChange}
                      className="form-control time-input"
                      placeholder="MM"
                      min="0"
                      max="59"
                    />
                  </div>
                </div>
              ) : null}{" "}
            </div>
          ))}
        </div>
      </div>
      <div className="border-bottom"></div>
      <div className="btnblue px-4 pb-5 mt-1">
        <button onClick={clickSubmitHandler}>Submit</button>
      </div>
    </>
  );
};

export default AddShift;

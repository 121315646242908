import React, { useEffect, useState } from "react";
import RightCustomModal from "../../Common/RightModal";
import AddShift from "../AddNewShift/AddShift"; // Import AddShift component
import { getSettingShift, shiftdeleteData } from "../../redux/Action";
import { useDispatch, useSelector } from "react-redux";
import Delete from "../../Common/Delete/Delete";
import SpinnerLoader from "../../components/common/spinnerloader/SpinnerLoader";

const Shift = () => {
  const dispatch = useDispatch();
  const { allShiftList, deleteShiftlist } = useSelector((state) => state.main);
  const pageLoading = useSelector((state) => state.main.loading);

  const [openInviteMember, setOpenInviteMember] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();

  useEffect(() => {
    dispatch(getSettingShift());
  }, [deleteShiftlist]);
  const handleClose = () => {
    setOpenInviteMember(false);
  };
  const onCloseModal = () => setOpen(false);

  const onOpenModal = (data) => {
    setSelectedUserId(data);
    setOpen(true);
  };
  const handleDelete = () => {
    dispatch(shiftdeleteData(selectedUserId?._id));
    onCloseModal();
  };

  const fields = [
    { label: "Shift Name", id: "name" },
    { label: "Shift Code", id: "shiftCode" },
    { label: "Shift Hour", id: "workingHour" },
    { label: "Start Time", id: "start_time" },
    { label: "End Time", id: "end_time" },
    { label: "Grace In Time", id: "graceInTime" },
    { label: "Grace Out Time", id: "graceOutTime" },
    { label: "Working Hour", id: "workingHour" },
  ];

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 pt-4">
          <div className="text_btn">
            <div className="company_name">
              <h1>Shift</h1>
            </div>
            <div className="btn_add">
              <button onClick={() => setOpenInviteMember(true)}>
                Add Shift
              </button>
            </div>
          </div>

          <div className="comp-table">
            <div className="table-responsive">
              {pageLoading ? (
                <SpinnerLoader />
              ) : (
                <table className="table table-bordered">
                  <thead>
                    <tr style={{ borderBottom: " 1px solid #2C357D2E" }}>
                      {fields.map((field) => (
                        <th
                          key={field.id}
                          style={
                            field.id === "shiftName"
                              ? { borderLeft: "none" }
                              : { borderLeft: "none" }
                          }
                        >
                          {field.label}
                        </th>
                      ))}
                      <th style={{ width: "18%", borderRight: "none" }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allShiftList?.data?.map((shift, index) => (
                      <tr key={index}>
                        {fields.map((field) => (
                          <td key={field.id}>{shift[field.id] || "-"}</td>
                        ))}
                        <td>
                          <span className="d-flex align-item-center justify-content-center gap-3">
                            <button>Save</button>
                            <button onClick={() => onOpenModal(shift)}>
                              Delete
                            </button>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>

      {openInviteMember && (
        <RightCustomModal
          isOpen={openInviteMember}
          onClose={handleClose}
          shouldCloseOnOutsideClick={true}
        >
          <AddShift onClose={handleClose} />
        </RightCustomModal>
      )}
      <>
        {open && (
          <Delete open={open} onClose={onCloseModal} onConfirm={handleDelete} />
        )}
      </>
    </>
  );
};

export default Shift;

import React, { useEffect, useState } from "react";
import "./tableList.css";
import RightCustomModal from "../../Common/RightModal";
import AddCompany from "../AddNewCompany/AddCompany";
import pink_logo from "../../assets/img/pink_logo.png";
import black_logo from "../../assets/img/black_logo.png";
import { useDispatch, useSelector } from "react-redux";
import { companydeleteData, getSettingComapnay } from "../../redux/Action";
import Delete from "../../Common/Delete/Delete";
import SpinnerLoader from "../../components/common/spinnerloader/SpinnerLoader";

const Company = () => {
  const dispatch = useDispatch();
  const [openInviteMember, setOpenInviteMember] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();

  const { allCompanylist, deleteCompanylist } = useSelector(
    (state) => state.main
  );
  const pageLoading = useSelector((state) => state.main.loading);

  useEffect(() => {
    dispatch(getSettingComapnay());
  }, [deleteCompanylist]);

  const handleClose = () => {
    setOpenInviteMember(false);
  };

  const onCloseModal = () => setOpen(false);

  const onOpenModal = (data) => {
    setSelectedUserId(data);
    setOpen(true);
  };
  const handleDelete = () => {
    dispatch(companydeleteData(selectedUserId?.id));
    onCloseModal();
  };
  const fields = [
    { label: "Company Name", name: "company_name" },
    { label: "Company Address", name: "address" },
    { label: "Country", name: "country_id" },
    { label: "State", name: "state_id" },
    { label: "Time Zone", name: "timezone" },
    { label: "Currency", name: "currency" },
    { label: "PF No.", name: "pf_no" },
    { label: "TAN No.", name: "tan_no" },
    { label: "PAN No.", name: "pan_no" },
    { label: "ESI No.", name: "esi_no" },
    { label: "LIN No.", name: "lin_no" },
    { label: "GST No.", name: "gst_no" },
    {
      label: "Registration Certification No.",
      name: "registration_certification_no",
    },
    { label: "Company Logo", name: "company_logo" },
    { label: "Signature", name: "signature" },
  ];

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 pt-4">
          <div className="text_btn">
            <div className="company_name">
              <h1>Company</h1>
            </div>
            <div className="btn_add">
              <button onClick={() => setOpenInviteMember(true)}>
                Add Company
              </button>
            </div>
          </div>

          <div className="comp-table">
            <div className="table-responsive">
              {pageLoading ? (
                <SpinnerLoader />
              ) : (
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      {fields.map((field) => (
                        <th
                          key={field.id}
                          style={
                            field.id === "companyName"
                              ? { borderLeft: "none" }
                              : { borderLeft: "none" }
                          }
                        >
                          {field.label}
                        </th>
                      ))}
                      <th style={{ borderRight: "none" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allCompanylist?.data?.map((company, index) => (
                      <tr key={index}>
                        {fields.map((field) => (
                          <td key={field.name}>
                            {field.name === "company_logo" ||
                            field.name === "signature" ? (
                              <img
                                src={company[field.name]}
                                alt={field.label}
                                className={
                                  field.name === "company_logo"
                                    ? "company-logo"
                                    : "signature"
                                }
                              />
                            ) : (
                              company[field.name]
                            )}
                          </td>
                        ))}
                        <td>
                          <span className="d-flex align-item-center justify-content-center gap-3">
                            <button>Save</button>
                            <button onClick={() => onOpenModal(company)}>
                              Delete
                            </button>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>

      {openInviteMember && (
        <RightCustomModal
          isOpen={openInviteMember}
          onClose={handleClose}
          shouldCloseOnOutsideClick={true}
        >
          <AddCompany onClose={handleClose} />
        </RightCustomModal>
      )}
      <>
        {open && (
          <Delete open={open} onClose={onCloseModal} onConfirm={handleDelete} />
        )}
      </>
    </>
  );
};

export default Company;

import React from "react";

const SpinnerLoader = () => {
  const loaderStyle = {
    position: "fixed", // Ensures the loader stays fixed to the viewport
    top: "50%", // Centers vertically
    left: "50%", // Centers horizontally
    transform: "translate(-50%, -50%)", // Corrects the offset caused by centering
    zIndex: 1000, // Ensures the loader is above other elements
    display: "flex", // Flexbox for centering content
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.7)", // Optional: semi-transparent background
    width: "100%", // Full-page width
    height: "100%", // Full-page height
  };

  const spinnerStyle = {
    width: "3rem",
    height: "3rem",
    borderWidth: "0.3rem",
    borderColor: "navy transparent transparent transparent", // Custom color
  };

  return (
    <div style={loaderStyle}>
      <div className="spinner-border" role="status" style={spinnerStyle}>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default SpinnerLoader;

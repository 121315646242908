import React, { useState } from "react";
import link from "../../assets/img/link.png";
import profile from "../../assets/img/profile.png";
import "./resignation.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addCommentforResignation,
  getResignationDetaildata,
  putResignationRejacedApprovedata,
} from "../../redux/Action";
import moment from "moment";

import { useEffect } from "react";
const ViewResignation = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { allResignationDetailsList } = useSelector((state) => state?.main);
  const [commentValue, setCommentValue] = useState();

  const pathname = location.pathname;

  const pathParts = pathname.split("/");

  const userId = pathParts[pathParts.length - 1];
  const Attendance = {
    records: [
      {
        status: "Store Manager",
        reason: allResignationDetailsList?.data?.adminName,
      },
      {
        status: "Manager",
        reason: allResignationDetailsList?.data?.managerName,
      },
      {
        status: "Shift Time",
        reason: allResignationDetailsList?.data?.shiftName,
      },
    ],
  };
  useEffect(() => {
    dispatch(getResignationDetaildata(userId));
  }, [dispatch, userId]);

  const handleChange = (status) => {
    dispatch(putResignationRejacedApprovedata(status, userId));
    navigate("/resignation");
  };

  const commentChangeHandler = (e) => {
    setCommentValue({ ...commentValue, [e.target.name]: e.target.value });
  };

  const submitCommentHandler = () => {
    const data = {
      comment: commentValue?.comment,
    };

    dispatch(addCommentforResignation(data, userId, navigate, setCommentValue));
  };

  const liStyle = {
    color: "#2C357D",
  };
  const parastyle = {
    color: "#000",
  };
  return (
    <div className="content-wrapper">
      <div className="wrap">
        <div className="container-fluid flex-grow-1 pt-4">
          <div className="logo_texts">
            <div className="logo_icons">
              {allResignationDetailsList?.data ? (
                <img
                  src={allResignationDetailsList.data.profileImage}
                  alt="Profile"
                  width={100}
                  height={100}
                  style={{ objectFit: "cover", borderRadius: "50px" }}
                />
              ) : (
                <p>No image available</p>
              )}{" "}
            </div>
            <div className="d-flex flex-column justify-content-start ">
              <div className="des_texts d-flex  justify-content-start gap-3 pr-4">
                <h5> {allResignationDetailsList?.data?.adminName}</h5>

                <p style={liStyle}>
                  {allResignationDetailsList?.data?.appliedDate
                    ? moment(allResignationDetailsList.data.appliedDate).format(
                        "DD MMMM YYYY : h:mm A"
                      )
                    : "No date available"}
                </p>
              </div>
              <div className="d-flex lign-item-center justify-content-start gap-2">
                <span
                  style={liStyle}
                  className="d-flex lign-item-start justify-content-start gap-2"
                >
                  to:{" "}
                  <p style={parastyle}>
                    {allResignationDetailsList?.data?.employee_email}
                  </p>{" "}
                </span>
                <span
                  style={liStyle}
                  className="d-flex lign-item-start justify-content-start gap-2"
                >
                  cc:{" "}
                  <p style={parastyle}>
                    {allResignationDetailsList?.data?.adminEmail}
                  </p>
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex align-item-center justify-content-center gap-3">
            {Attendance?.records?.map((record, index) => (
              <div className="cardmornings">
                <div className="right_reqs">
                  <h4>{record?.status}</h4>
                  <div className="min_flex_reqs">
                    <h1>{record?.reason}</h1>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="para_lorems">
            <div className="head_viewss">
              <div className="application_pins">
                <h6>Resign Application</h6>
                <img src={link} alt="" />
              </div>
            </div>
            <div className="lorem_texts">
              <p>{allResignationDetailsList?.data?.reason}</p>
            </div>
          </div>
          <div className="btn_accept">
            <div className="accept_1">
              <button onClick={() => handleChange("Approved")}>Accept</button>
            </div>
            <div className="reject_1">
              <button onClick={() => handleChange("Rejected")}>Reject</button>
            </div>
          </div>
          <div className="add_textarea">
            <div className="heading_add">
              <h3>Add Comment</h3>
            </div>
            <textarea
              placeholder="Describe yourself here..."
              onChange={commentChangeHandler}
              name="comment"
            ></textarea>
          </div>
          <div className="submit_buttons">
            <button onClick={() => submitCommentHandler()}>Submit</button>
          </div>
          <div className="para_loremss">
            <div className="head_views">
              <div className="application_pins">
                <h6>History of comments</h6>
              </div>
            </div>
            <div className="lorem_textss border-bottom-0">
              {allResignationDetailsList?.data?.comments?.map((data) => (
                <p>{data}</p>
              ))}
            </div>
          </div>

          {/* <div className="canditate_selector">
        <Selector label="Blood Group" />
      </div> */}
          {/* <div className="submit_buttons">
          <button>Submit</button>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default ViewResignation;

import React, { useState } from "react";
import "./addnew.css";
import { Navigate, useNavigate } from "react-router-dom";
import { VscClose } from "react-icons/vsc";
import { useDispatch } from "react-redux";
import { addSettingCompany } from "../../redux/Action";

const AddCompany = ({ onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fields = [
    { label: "Company Name", type: "text", name: "company_name" },
    { label: "Company Address", type: "text", name: "address" },
    {
      label: "Country",
      type: "select",
      name: "country_id",
      options: ["1", "2", "3"],
    },
    {
      label: "State",
      type: "select",
      name: "state_id",
      options: ["11", "22", "21"],
    },
    {
      label: "Time Zone",
      type: "select",
      name: "timezone",
      options: ["PST", "EST", "CST"],
    },
    {
      label: "Currency",
      type: "select",
      name: "currency",
      options: ["USD", "CAD", "GBP"],
    },
    { label: "PF No.", type: "text", name: "pf_no" },
    { label: "PAN No.", type: "text", name: "pan_no" },
    { label: "TAN No.", type: "text", name: "tan_no" },
    { label: "ESI No.", type: "text", name: "esi_no" },
    { label: "LIN No.", type: "text", name: "lin_no" },
    { label: "GST No.", type: "text", name: "gst_no" },
    {
      label: "Registration Certification No.",
      type: "text",
      name: "registration_certification_no",
    },
    {
      label: "Company Logo",
      type: "file",
      name: "c_logo",
    },
    {
      label: "Signature",
      type: "file",
      name: "signature",
    },
  ];

  const [formData, setFormData] = useState({
    company_name: "",
    address: "",
    country_id: "",
    state_id: "",
    timezone: "",
    currency: "",
    pf_no: "",
    pan_no: "",
    tan_no: "",
    esi_no: "",
    lin_no: "",
    gst_no: "",
    registration_certification_no: "",
    c_logo: "",
    signature: "",
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (files && files.length > 0) {
      setFormData((prev) => ({
        ...prev,
        [name]: files[0],
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const clickSubmitHandler = () => {
    const payload = new FormData();

    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null) {
        if (formData[key] instanceof File) {
          payload.append(key, formData[key]);
        } else {
          payload.append(key, formData[key]);
        }
      }
    });

    dispatch(addSettingCompany(payload, navigate));
  };

  return (
    <>
      <div className="addcompany">
        <div className="d-flex justify-content-between">
          <h2>Add New Company</h2>
          <VscClose size={20} onClick={onClose} />
        </div>
      </div>
      <div className="border-bottom"></div>
      <div
        className="d-flex justify-content-between gap-1 content_common"
        style={{ borderBottom: "2px solid #2C357D2E" }}
      >
        <div className="label_name">
          {fields.map((field) => (
            <label key={field.name} htmlFor={field.name}>
              {field.label}
              {field.label.includes("*") && (
                <span style={{ color: "red" }}>*</span>
              )}
            </label>
          ))}
        </div>
        <div className="input_group mx-auto">
          {fields.map((field) => (
            <div key={field.name}>
              {field.type === "select" ? (
                <select
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  className="form-group"
                >
                  <option value=""></option>
                  {field.options.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              ) : field.type === "file" ? (
                <input
                  className="form-group"
                  type="file"
                  name={field.name}
                  onChange={handleChange}
                />
              ) : (
                <input
                  className="form-group"
                  type={field.type}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  placeholder={`Enter ${field.label}`}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="border-bottom"></div>
      <div className="btnblue px-4 pb-5 mt-1">
        <button onClick={clickSubmitHandler}>Submit</button>
      </div>
    </>
  );
};

export default AddCompany;

import React, { useState } from "react";
import "./addrole.css"; // Assuming you have CSS to style the form
import { VscClose } from "react-icons/vsc";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addSettingRole } from "../../redux/Action";

const AddRole = ({ onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fields = [
    { label: "Role Name", type: "text", id: "name" },
    {
      label: "Role Description",
      type: "text",
      id: "description",
    },
  ];

  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const clickSubmitHandler = () => {
    const payload = new FormData();

    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null) {
        if (formData[key] instanceof File) {
          payload.append(key, formData[key]);
        } else {
          payload.append(key, formData[key]);
        }
      }
    });

    dispatch(addSettingRole(payload, navigate));
  };

  return (
    <>
      <div className="addrole">
        <div className="d-flex justify-content-between">
          <h2>Add New Role</h2>
          <VscClose size={20} onClick={onClose} />
        </div>
      </div>
      <div className="border-bottom"></div>
      <div
        className="d-flex justify-content-between gap-1 content_common"
        style={{ borderBottom: "2px solid #2C357D2E" }}
      >
        {" "}
        <div className="label_name">
          {fields.map((field) => (
            <label key={field.id} htmlFor={field.id}>
              {field.label}
            </label>
          ))}
        </div>
        <div className="input_group mx-auto">
          {fields?.map((field) => (
            <div key={field.id}>
              {field.type === "file" ? (
                <>
                  {/* <div className="d-flex file-input-container" > */}
                  <div className="d-flex ">
                    <input
                      type="file"
                      id={field.id}
                      accept="image/*"
                      onChange={handleChange}
                      className="form-group"
                      // className="form-group file-input  "
                    />
                  </div>
                </>
              ) : (
                <input
                  className="form-group"
                  type={field.type}
                  id={field.id}
                  value={formData[field.id]}
                  onChange={handleChange}
                  placeholder={`Enter ${field.label}`}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="border-bottom"></div>
      <div className="btnblue px-4 pb-5 mt-1">
        <button onClick={clickSubmitHandler}>Submit</button>
      </div>
    </>
  );
};

export default AddRole;

import React, { useState, useEffect, useRef } from "react";
import "./user.css";
import TableSection from "../../../components/common/customtable/TableSection";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../../redux/Action";
import white_filter from "../../../assets/img/white_filter.svg";
import downdrop from "../../../assets/img/downdrop.svg";

const User = () => {
  const dispatch = useDispatch();
  const selectRef = useRef(null); // Ref for the select element

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchName, setSearchName] = useState("");
  const [searchId, setSearchId] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const { getAllUserList, deleteUser } = useSelector((state) => state?.main);

  useEffect(() => {
    dispatch(getUsers(currentPage, limit));
  }, [dispatch, currentPage, limit, deleteUser]);

  // Handle Find Employee button click
  const handleFindEmployee = () => {
    const data = getAllUserList?.data || [];
    const filtered = data.filter((user) => {
      const matchesName =
        !searchName ||
        user.name?.toLowerCase().includes(searchName.toLowerCase());
      const matchesId = !searchId || user.emp_id?.toString().includes(searchId);
      const matchesRole =
        !selectedRole ||
        user.designation?.toLowerCase() === selectedRole.toLowerCase();

      return matchesName && matchesId && matchesRole;
    });
    setFilteredData(filtered);
  };

  return (
    <>
      <div className="table_wrapper">
        <div className="top_section">
          <div className="col-lg-12 col-md-12">
            <div className="right-item d-flex justify-content-start gap-3">
              <form className="d-flex" style={{ width: "100%" }}>
                {/* Employee Name Filter */}
                <div className="outer_border border-right-0 border-left-0">
                  <input
                    type="text"
                    placeholder="Employee Name"
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                  />
                </div>
                {/* Employee ID Filter */}
                <div className="outer_border border-right-0">
                  <input
                    type="text"
                    placeholder="Employee ID"
                    value={searchId}
                    onChange={(e) => setSearchId(e.target.value)}
                  />
                </div>
                {/* Employee Role Filter */}
                <div className="outer_border">
                  <img
                    src={downdrop}
                    alt="Open dropdown"
                    onClick={() => selectRef.current?.click()}
                  />
                  <select
                    ref={selectRef}
                    onChange={(e) => setSelectedRole(e.target.value)}
                  >
                    <option value="">Employee Designation</option>
                    <option value="Manager">Manager</option>
                    <option value="Developer">Developer</option>
                    <option value="Designer">Designer</option>
                  </select>
                </div>
              </form>
              {/* Find Employee Button */}
              <div className="btn_findEmployee">
                <button type="button" onClick={handleFindEmployee}>
                  Find Employee
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Render Table with Filtered Data */}
      <div className="table_wrapper">
        <div className="table_content">
          <div className="row_wrap row">
            <div className="col-lg-12">
              <div className="card">
                <TableSection
                  tableData={
                    filteredData.length > 0
                      ? filteredData
                      : getAllUserList?.data
                  }
                  columns={[
                    "No.",
                    "Store",
                    "Employee ID",
                    "Employee name ",
                    "Role",
                    "Shift Time",
                    "Slot time",
                    "Status",
                  ]}
                  setCurrentPage={setCurrentPage}
                  getAllUserList={getAllUserList}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default User;

import React, { useState, useEffect } from "react";
import RightCustomModal from "../../Common/RightModal";
import AddDesgination from "../AddNewDesgination/AddDesgination";
import {
  designationdeleteData,
  getSettingResignation,
  updateDesignation,
} from "../../redux/Action";
import { useDispatch, useSelector } from "react-redux";
import Delete from "../../Common/Delete/Delete";
import SpinnerLoader from "../../components/common/spinnerloader/SpinnerLoader";

const Designation = () => {
  const dispatch = useDispatch();

  const [openInviteMember, setOpenInviteMember] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();
  const [selectedReporting, setSelectedReporting] = useState({}); // Track selected reporting designations

  const { allResignationList, deleteDesignationlist } = useSelector(
    (state) => state.main
  );
  const pageLoading = useSelector((state) => state.main.loading);

  const handleClose = () => {
    setOpenInviteMember(false);
  };

  useEffect(() => {
    dispatch(getSettingResignation());
  }, [deleteDesignationlist]);

  const onCloseModal = () => setOpen(false);

  const onOpenModal = (data) => {
    setSelectedUserId(data);
    setOpen(true);
  };

  const handleDelete = () => {
    dispatch(designationdeleteData(selectedUserId?._id));
    onCloseModal();
  };
  const handleSave = (data) => {
    const updatedData = {
      updatedId: data._id,
    };
    dispatch(updateDesignation(data, updatedData));
  };

  const handleReportingChange = (id, value) => {
    setSelectedReporting((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const fields = [
    { label: "Designation Name", id: "name" },
    { label: "Reporting Designation", id: "reposting_designation_name" },
  ];

  const reportingOptions = allResignationList?.data?.map(
    (item) => item?.reposting_designation_name
  );

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 pt-4">
          <div className="text_btn">
            <div className="company_name">
              <h1>Designation</h1>
            </div>
            <div className="btn_add">
              <button onClick={() => setOpenInviteMember(true)}>
                Add Designation
              </button>
            </div>
          </div>

          <div className="comp-table">
            <div className="table-responsive">
              {pageLoading ? (
                <SpinnerLoader />
              ) : (
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      {fields.map((field) => (
                        <th
                          key={field.id}
                          style={{
                            textAlign:
                              field.id === "reposting_designation_name"
                                ? "center"
                                : "left",
                            width:
                              field.id === "name"
                                ? "30%"
                                : field.id === "reposting_designation_name"
                                ? "50%"
                                : "auto", // Default for other fields if needed
                            borderLeft: "none",
                          }}
                        >
                          {field.label}
                        </th>
                      ))}
                      <th
                        style={{
                          textAlign: "center",
                          width: "20%",
                          borderRight: "none",
                        }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {allResignationList?.data?.map((store, index) => (
                      <tr
                        key={index}
                        style={{ borderBottom: "1px solid #2C357D2E" }}
                      >
                        {fields.map((field) => (
                          <td
                            key={field.id}
                            style={{
                              textAlign:
                                field.id === "reposting_designation_name"
                                  ? "center"
                                  : "left",
                              width:
                                field.id === "name"
                                  ? "30%"
                                  : field.id === "reposting_designation_name"
                                  ? "50%"
                                  : "auto",
                            }}
                          >
                            {field.id === "reposting_designation_name" ? (
                              <select
                                style={{
                                  border: "none",
                                  borderBottom: "1px solid #2c357d",
                                  outline: "none",
                                  background: "transparent",
                                  textAlign: "center",
                                }}
                                value={
                                  selectedReporting[store._id] ||
                                  store[field.id]
                                }
                                onChange={(e) =>
                                  handleReportingChange(
                                    store._id,
                                    e.target.value
                                  )
                                }
                              >
                                {reportingOptions.map((option, i) => (
                                  <option key={i} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              store[field.id]
                            )}
                          </td>
                        ))}
                        <td
                          style={{
                            textAlign: "center",
                            width: "20%",
                          }}
                        >
                          <span className="d-flex align-items-center justify-content-center gap-3">
                            <button onClick={() => handleSave(store)}>
                              Save
                            </button>
                            <button onClick={() => onOpenModal(store)}>
                              Delete
                            </button>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>

      {openInviteMember && (
        <RightCustomModal
          isOpen={openInviteMember}
          onClose={handleClose}
          shouldCloseOnOutsideClick={true}
        >
          <AddDesgination onClose={handleClose} />
        </RightCustomModal>
      )}
      <>
        {open && (
          <Delete open={open} onClose={onCloseModal} onConfirm={handleDelete} />
        )}
      </>
    </>
  );
};

export default Designation;

import React, { useEffect, useState } from "react";
import fileicon from "../../../assets/img/fileicon.png";
import "./inputlogo.css";
import { addDocumentUpload } from "../../../redux/Action";

const InputLogo = ({
  name,
  value,
  onChangeData,
  error,
  touched,
  width,
  showIcon = true,
  buttonPadding = "15px 30px",
  accept = ".jpeg, .jpg, .png, .pdf, .docx",
  docx,
  dispatch,
  type,
  itemType,
  docsData,
}) => {
  const [fileName, setFileName] = useState(value || "No file chosen");

  // const [enabledUploads, setEnabledUploads] = useState(
  //   itemType.reduce((acc, item, index) => {
  //     acc[item.name] = index === 0;
  //     return acc;
  //   }, {})
  // );

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      setFileName(file.name);

      if (docx && type) {
        const userData = new FormData();
        userData.append("file", file);

        // Disable all fields except the current one
        // setEnabledUploads(() =>
        //   itemType.reduce((acc, item) => {
        //     acc[item.name] = item.name === type; // Only the current field is enabled
        //     return acc;
        //   }, {})
        // );

        // try {

        const docitem = localStorage.getItem("docxid");
        if (
          type !== "relievingLetter" &&
          type !== "paySlip" &&
          type !== "aadharCardDocument" &&
          type !== "panCardDocument" &&
          type !== "passBookOrCancelledCheque"
        ) {
          dispatch(addDocumentUpload(userData, type, docitem));
        } else {
          onChangeData(file);
        }

        //   if (response.success) {

        //     setEnabledUploads((prevState) => {
        //       const updatedState = { ...prevState };
        //       console.log("ggg", updatedState);
        //       const currentIndex = itemType.findIndex(
        //         (item) => item.name === response.type
        //       );
        //       console.log("ffsdfds", currentIndex);

        //       if (currentIndex >= 0 && currentIndex < itemType.length - 1) {
        //         const nextField = itemType[currentIndex + 1].name;
        //         updatedState[nextField] = true;
        //       }

        //       updatedState[type] = false;
        //       console.log(updatedState, "jjjj");
        //       return updatedState;
        //     });
        //   } else {
        //     setEnabledUploads((prevState) => ({
        //       ...prevState,
        //       [type]: true,
        //     }));
        //   }
        // } catch (error) {
        //   console.error("Upload Error:", error);

        //   setEnabledUploads((prevState) => ({
        //     ...prevState,
        //     [type]: true,
        //   }));
        // }
      }
    }
  };

  // console.log(enabledUploads, "enabledUploads");
  // console.log(enabledUploads[name], "enabledUploads");

  return (
    <div className="input-logo-container">
      {docsData?.img ? (
        <img src={docsData?.img} alt="no file" style={{ width: "15%" }} />
      ) : (
        <>
          <label className="input-logo-label" htmlFor={`file-input-${name}`}>
            Upload Document (jpeg, png, jpg, pdf, docx only)
          </label>
          <div className="form-group">
            <div
              className="file-upload-wrapper"
              style={{ width: width || "100%" }}
            >
              <div className="file-icon" style={{ height: "35px" }}>
                {showIcon && (
                  <img
                    src={fileicon}
                    alt="file icon"
                    style={{ width: "15%" }}
                  />
                )}
              </div>
              <div className="file-name">{fileName}</div>
              <button
                className="btn-upload"
                style={{ padding: buttonPadding }}
                type="button"
                // disabled={!enabledUploads[name]}
                onClick={() =>
                  document.getElementById(`file-input-${name}`).click()
                }
              >
                Upload
              </button>
              <input
                type="file"
                id={`file-input-${name}`}
                style={{ display: "none" }}
                onChange={handleFileChange}
                accept={accept}
              />
            </div>
            {error && touched && <div className="error-message">{error}</div>}
          </div>
        </>
      )}
    </div>
  );
};

export default InputLogo;

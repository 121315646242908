import React, { useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  Avatar_1,
  Avatar_5,
  Avatar_6,
  Avatar_7,
  Profile,
} from "../../assets/img/dashboard";
import {
  IconBell,
  IconMenu,
  IconSearch,
} from "../../assets/img/dashboard/icons";
import menu from "../../assets/img/menu.svg";
import ProfileIcon from "../../assets/img/profileIcon.png";
import Logout from "../../assets/img/logout.png";
import profilePic from "../../assets/img/profilePic.png";
import bellicon from "../../assets/img/bellicon.svg";
import Frame from "../../assets/icons/Frame.png";
import { useRef } from "react";
import { useEffect } from "react";
import { getSearchUser, logout } from "../../redux/Action";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

const Navbar = ({ funtype, isMenuBar, showAnimation }) => {
  const [isOpen, setIsOpen] = useState(false);
  // const pathname = location.pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const access_token = localStorage.getItem("accessToken");
  const dropdownRef = useRef(null);
  const { allusersearch } = useSelector((state) => state.main); // Adjust based on your reducer
  const [query, setQuery] = useState("");
  const toggleDropdown = () => {
    setIsOpen(true);
  };
  const handleProfileClick = () => {
    if (access_token) {
      navigate("/profile");
    } else {
      navigate("/");
    }
    setIsOpen(false);
  };
  const handleLogout = () => {
    const access_token = localStorage.getItem("refreshToken");
    const data = {
      refreshToken: access_token,
    };
    dispatch(logout(data, navigate));
  };

  const handleSearch = debounce((name) => {
    if (name) {
      dispatch(getSearchUser(name));
      // navigate("/user/viewuser");
    }
  }, 400);

  useEffect(() => {
    handleSearch(query);
    return handleSearch.cancel;
  }, [query]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  const handleClick = (data) => {
    navigate(`/user/viewuser/${data._id}`);
  };

  return (
    <>
      <nav
        className="navbar navbar-expand-xl align-items-center top-bar"
        id="layout-navbar"
      >
        <div
          className="navbar-nav-right d-flex align-items-center "
          id="navbar-collapse"
        >
          <div className="d-flex align-items-center justify-content-flex ">
            <img src={menu} alt="" onClick={funtype} />
          </div>
          <div className="text_head">
            <h4>Good morning, Shiv</h4>
          </div>
          <div className="navbar-nav align-items-center justify-content-end bg-navbar-theme ms-auto search-bar">
            <div className="nav-item d-flex align-items-center justify-content-end gap-2 w-100">
              <IconSearch size={30} className="i" />
              <input
                type="text"
                className="border-0 shadow-none p-0"
                placeholder="Quick Search..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            {query && (
              <div
                className="search-results border mt-2 p-2 position-absolute "
                style={{
                  left: "0px",
                  right: "0px",
                  width: "100%",
                  top: "35px",
                  background: "#e5f1f8",
                  maxHeight: "320px",
                  overflow: "scroll",
                }}
              >
                {allusersearch?.length > 0 ? (
                  allusersearch.map((user) => (
                    <div
                      key={user._id}
                      className="search-result-item p-1"
                      onClick={() => handleClick(user)}
                    >
                      {user.name}
                    </div>
                  ))
                ) : (
                  <div className="text-muted">No results found</div>
                )}
              </div>
            )}
          </div>
          <span
            className="line_right
          "
          ></span>
          <ul className="navbar-nav flex-row align-items-center  profile-bar">
            <li className="nav-item lh-1 bg-navbar-theme dropdown-notifications navbar-dropdown dropdown ">
              <a
                className="nav-link  hide-arrow"
                //  href="javascript:void(0);"
                data-toggle="dropdown"
                data-close="outside"
                aria-expanded="false"
                contenteditable="false"
                style={{ cursor: "pointer" }}
              >
                <span className="position-relative">
                  {/* <i className="bx bx-bell bx-md"></i> */}
                  <img src={bellicon} alt="" />
                  <span className="badge rounded-pill bg-danger badge-dot badge-notifications border"></span>
                </span>
              </a>
            </li>
            <ul className="navbar-nav flex-row align-items-center profile-bar">
              <li
                className="nav-item dropdown d-flex   align-items-center"
                ref={dropdownRef}
              >
                <a
                  className="nav-link "
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-expanded={isOpen ? "true" : "false"}
                  onClick={toggleDropdown}
                >
                  <img
                    src={profilePic}
                    alt="user"
                    className="rounded-circle"
                    style={{ width: "40px" }}
                  />
                </a>
                <div className="admin_text">
                  <p>Admin</p>
                  <p>Admin admin@domain.in</p>
                </div>
                <ul className="dropdown-menu dropdown-menu-end rounded-4  ">
                  <li className="dropdown-item" onClick={handleProfileClick}>
                    <img src={ProfileIcon} className="" alt="" />
                    <span className="px-2">Profile</span>
                  </li>
                  <li className="dropdown-item">
                    <span className="px-2"> Notification</span>
                    <img src={Frame} className="" alt="" />
                  </li>
                  <li className="dropdown-item" onClick={handleLogout}>
                    <img src={Logout} className="" alt="" />
                    <span className="px-2">Logout</span>
                  </li>
                </ul>
              </li>
            </ul>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;

import React, { useEffect, useState } from "react";
import RightCustomModal from "../../Common/RightModal";
import AddStoreNew from "../AddStore/AddStoreNew";
import {
  getAllStores,
  getSettingStore,
  storedeleteData,
} from "../../redux/Action";
import { useDispatch, useSelector } from "react-redux";
import Delete from "../../Common/Delete/Delete";
import SpinnerLoader from "../../components/common/spinnerloader/SpinnerLoader";

const Store = () => {
  const dispatch = useDispatch();

  const [openInviteMember, setOpenInviteMember] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();
  const [open, setOpen] = useState(false);

  const { allStoreLists, deleteStorelist } = useSelector((state) => state.main);
  const pageLoading = useSelector((state) => state.main.loading);

  const handleClose = () => {
    setOpenInviteMember(false);
  };

  useEffect(() => {
    dispatch(getSettingStore());
  }, [deleteStorelist]);

  const onCloseModal = () => setOpen(false);

  const onOpenModal = (data) => {
    setSelectedUserId(data);
    setOpen(true);
  };
  const handleDelete = () => {
    dispatch(storedeleteData(selectedUserId?._id));
    onCloseModal();
  };

  const fields = [
    { label: "Store Name", id: "name" },
    { label: "Company Name", id: "company_name" },
    { label: "Store Address", id: "address" },
  ];

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 pt-4">
          <div className="text_btn">
            <div className="company_name">
              <h1>Store</h1>
            </div>
            <div className="btn_add">
              <button onClick={() => setOpenInviteMember(true)}>
                Add Store
              </button>
            </div>
          </div>

          <div className="comp-table">
            <div className="table-responsive">
              {pageLoading ? (
                <SpinnerLoader />
              ) : (
                <table className="table table-bordered">
                  <thead>
                    <tr style={{ borderBottom: " 1px solid #2C357D2E" }}>
                      {fields.map((field) => (
                        <th
                          key={field.id}
                          style={
                            field.id === "name" ? { borderLeft: "none" } : {}
                          }
                        >
                          {field.label}
                        </th>
                      ))}
                      <th style={{ borderRight: "none" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allStoreLists?.data.map((store, index) => (
                      <tr key={index}>
                        {fields.map((field) => (
                          <td key={field.id}>{store[field.id]}</td>
                        ))}
                        <td>
                          <span className="d-flex align-item-center justify-content-center gap-3">
                            <button>Save</button>
                            <button onClick={() => onOpenModal(store)}>
                              Delete
                            </button>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>

      {openInviteMember && (
        <RightCustomModal
          isOpen={openInviteMember}
          onClose={handleClose}
          shouldCloseOnOutsideClick={true}
        >
          <AddStoreNew
            setOpenInviteMember={setOpenInviteMember}
            onClose={handleClose}
          />
        </RightCustomModal>
      )}
      <>
        {open && (
          <Delete open={open} onClose={onCloseModal} onConfirm={handleDelete} />
        )}
      </>
    </>
  );
};

export default Store;

import React, { useEffect } from "react";
import Form from "../../../Common/Form/Form";
import "../../../assets/img/camera.png";
import "./addEmployee.css";
import Rectangle from "../../../assets/img/Rectangle.png";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountryList } from "../../../redux/Action";

const AddEmployee = () => {
  const dispatch = useDispatch();
  const { allCountryList, getAllStateData, getAllCityData } = useSelector(
    (state) => state?.main
  );

  useEffect(() => {
    dispatch(getAllCountryList());
  }, []);

  return (
    <div className="overview container ">
      <div className="top_bottomEmployees">
        <h4> Add Employee </h4>
      </div>

      <div className="row d-flex  justify-content-start pt-3">
        <div className="col-12 ">
          <div
            className="card"
            style={{ boxShadow: "0px 1px 15px 0px #b6bacb4d" }}
          >
            <div className="card-body ">
              <div className="card_flex">
                <div className="flex_img_text">
                  <div class="upload-section">
                    <div class="upload-container">
                      <input type="file" accept="image/*" />
                      <span class="icon">
                        <img src={Rectangle} alt="No image available" />
                      </span>
                    </div>
                  </div>
                  <div className="text_name  pt-3">
                    <h6>Miss SARANYA R.</h6>
                    <p>MVR - Orion</p>
                    <p>CASHIER</p>
                  </div>
                </div>
                <div className="border_dash_right"></div>
                <div className="detail_wrap">
                  <div className="pad_wrap">
                    <p>Phone:</p>
                    <p>+91 123456789</p>
                  </div>
                  <div className="pad_wrap">
                    <p>Email:</p>
                    <p>saranya@gmail.com</p>
                  </div>
                  <div className="pad_wrap">
                    <p>Employee Nick Name:</p>
                    <p>Sara</p>
                  </div>
                  <div className="pad_wrap">
                    <p>Gender:</p>
                    <p>Female</p>
                  </div>
                  <div className="pad_wrap">
                    <p>Report to:</p>
                    <p>RAJESH R.</p>
                  </div>
                </div>
                <div className="border_dash_right"></div>
                <div className="detail_wrap">
                  <div className="pad_wrap">
                    <p>Check In:</p>
                    <p>9:30 AM</p>
                  </div>
                  <div className="pad_wrap">
                    <p>Check Out:</p>
                    <p>7:00 PM</p>
                  </div>
                  <div className="pad_wrap">
                    <p>Total Working Hours:</p>
                    <p>9:30 Hours </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Form
          formType={AddEmployee}
          allCountryList={allCountryList}
          getAllStateData={getAllStateData}
          getAllCityData={getAllCityData}
        />
      </div>
    </div>
  );
};

export default AddEmployee;

import React, { useEffect, useState } from "react";
import RightCustomModal from "../../Common/RightModal";
import AddRole from "../AddNewRole/AddRole"; // Import AddRole component
import { getSettingRole, roledeleteData } from "../../redux/Action";
import { useDispatch, useSelector } from "react-redux";
import Delete from "../../Common/Delete/Delete";
import SpinnerLoader from "../../components/common/spinnerloader/SpinnerLoader";

const Role = () => {
  const dispatch = useDispatch();
  const { allRoleList, deleteRolelist } = useSelector((state) => state.main);
  const pageLoading = useSelector((state) => state.main.loading);

  const [openInviteMember, setOpenInviteMember] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();

  useEffect(() => {
    dispatch(getSettingRole());
  }, [deleteRolelist]);

  const fields = [
    { label: "Role Name", id: "name" },
    { label: "Role Description", id: "description" },
  ];
  const handleClose = () => {
    setOpenInviteMember(false);
  };

  const onCloseModal = () => setOpen(false);

  const onOpenModal = (data) => {
    setSelectedUserId(data);
    setOpen(true);
  };
  const handleDelete = () => {
    dispatch(roledeleteData(selectedUserId?._id));
    onCloseModal();
  };
  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 pt-4">
          <div className="text_btn">
            <div className="company_name">
              <h1>Role</h1>
            </div>
            <div className="btn_add">
              <button onClick={() => setOpenInviteMember(true)}>
                Add Role
              </button>
            </div>
          </div>

          <div className="comp-table">
            <div className="table-responsive">
              {pageLoading ? (
                <SpinnerLoader />
              ) : (
                <table className="table table-bordered">
                  <thead>
                    <tr style={{ borderBottom: " 1px solid #2C357D2E" }}>
                      {fields.map((field) => (
                        <th
                          key={field.id}
                          style={
                            field.id === "roleName"
                              ? { borderLeft: "none" }
                              : { borderLeft: "none" }
                          }
                        >
                          {field.label}
                        </th>
                      ))}
                      <th style={{ width: "10%", borderRight: "none" }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allRoleList?.data.map((Role, index) => (
                      <tr key={index}>
                        {fields.map((field) => (
                          <td key={field.id}>{Role[field.id]}</td>
                        ))}
                        <td>
                          <span className="d-flex align-item-center justify-content-center gap-3">
                            <button>Save</button>
                            <button onClick={() => onOpenModal(Role)}>
                              Delete
                            </button>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
      <>
        {open && (
          <Delete open={open} onClose={onCloseModal} onConfirm={handleDelete} />
        )}
      </>
      {openInviteMember && (
        <RightCustomModal
          isOpen={openInviteMember}
          onClose={handleClose}
          shouldCloseOnOutsideClick={true}
        >
          <AddRole onClose={handleClose} />
        </RightCustomModal>
      )}
    </>
  );
};

export default Role;

import React, { useState } from "react";
import "./pagination.css"; // Importing CSS

const Pagination = ({ listData, setPageData }) => {
  const [currentPage, setCurrentPage] = useState(listData?.currentPage || 1);

  const nextPage = () => {
    if (currentPage < listData?.total_page) {
      setPageData(currentPage + 1);
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setPageData(currentPage - 1);
      setCurrentPage(currentPage - 1);
    }
  };

  const changePages = (page) => {
    setPageData(page);
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const totalPages = listData?.total_page || 0;
    const pageNumbers = [];

    if (totalPages <= 5) {
      // Show all pages if total pages <= 5
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <li
            key={i}
            className={`page-item ${currentPage === i ? "active" : ""}`}
            onClick={() => changePages(i)}
          >
            <span className="page-link">{i}</span>
          </li>
        );
      }
    } else {
      // Always show the first two pages
      pageNumbers.push(
        <li
          key={1}
          className={`page-item ${currentPage === 1 ? "active" : ""}`}
          onClick={() => changePages(1)}
        >
          <span className="page-link">1</span>
        </li>
      );

      pageNumbers.push(
        <li
          key={2}
          className={`page-item ${currentPage === 2 ? "active" : ""}`}
          onClick={() => changePages(2)}
        >
          <span className="page-link">2</span>
        </li>
      );

      // Show dots if currentPage > 3
      if (currentPage > 3) {
        pageNumbers.push(
          <li key="dots-prev" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
      }

      // Show sliding window of pages around the current page
      for (
        let i = Math.max(3, currentPage - 1);
        i <= Math.min(totalPages - 2, currentPage + 1);
        i++
      ) {
        pageNumbers.push(
          <li
            key={i}
            className={`page-item ${currentPage === i ? "active" : ""}`}
            onClick={() => changePages(i)}
          >
            <span className="page-link">{i}</span>
          </li>
        );
      }

      // Show dots if currentPage is not close to the end
      if (currentPage < totalPages - 2) {
        pageNumbers.push(
          <li key="dots-next" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
      }

      // Always show the last two pages
      pageNumbers.push(
        <li
          key={totalPages - 1}
          className={`page-item ${
            currentPage === totalPages - 1 ? "active" : ""
          }`}
          onClick={() => changePages(totalPages - 1)}
        >
          <span className="page-link">{totalPages - 1}</span>
        </li>
      );

      pageNumbers.push(
        <li
          key={totalPages}
          className={`page-item ${currentPage === totalPages ? "active" : ""}`}
          onClick={() => changePages(totalPages)}
        >
          <span className="page-link">{totalPages}</span>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <div>
      {listData?.total_page > 1 && (
        <>
          <ul className="pagination_number">
            {/* Previous Button */}
            {currentPage > 1 && (
              <li className="page-item" onClick={prevPage}>
                <span className="page-link" aria-hidden="true">
                  ‹
                </span>
              </li>
            )}
            {/* Render Page Numbers */}
            {renderPageNumbers()}
            {/* Next Button */}
            {currentPage < listData?.total_page && (
              <li className="page-item" onClick={nextPage}>
                <span className="page-link" aria-label="Next »">
                  ›
                </span>
              </li>
            )}
          </ul>
        </>
      )}
    </div>
  );
};

export default Pagination;

import React, { useState, useEffect } from "react";
import req_user from "../../../assets/img/req_user.svg";
import cross from "../../../assets/img/cross.png";
import watch_user from "../../../assets/img/watch_user.svg";
import greydrop from "../../../assets/img/greydrop.png";
import ViewJob from "./ViewJob";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getjobList, jobdeleteData } from "../../../redux/Action";
import Pagination from "../../../Common/Pagination/Pagination";
import Delete from "../../../Common/Delete/Delete";
import SpinnerLoader from "../../../components/common/spinnerloader/SpinnerLoader";

const Jobs = () => {
  const [onRequest, setOnRequest] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUserId, setSelectedUserId] = useState();
  const [limit, setLimit] = useState(10);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allJobList, deleteJoblist } = useSelector((state) => state?.main);
  const pageLoading = useSelector((state) => state.main.loading);

  useEffect(() => {
    dispatch(getjobList(currentPage, limit));
  }, [currentPage, limit, deleteJoblist]);

  useEffect(() => {
    const totalJobs = allJobList?.data?.totalJobs || 0;
    const vacancies = allJobList?.data?.vacancies || 0;
    const filledPositions = allJobList?.data?.filledJobs || 0;

    const attendanceData = [
      {
        status: "Total Jobs",
        count: totalJobs,
        imgSrc: <img src={req_user} alt="Total Jobs" />,
      },
      {
        status: "Vacancies",
        count: vacancies,
        imgSrc: <img src={cross} alt="Vacancies" />,
      },
      {
        status: "Position Filled",
        count: filledPositions,
        imgSrc: <img src={watch_user} alt="Position Filled" />,
      },
    ];
    setAttendanceData(attendanceData);
  }, [allJobList]);

  const OpenRequest = (jobId) => {
    setOnRequest(true);
    navigate(`/job/viewjob/${jobId}`);
  };
  const onCloseModal = () => setOpen(false);

  const onOpenModal = (data) => {
    setSelectedUserId(data);
    setOpen(true);
  };
  const handleDelete = () => {
    dispatch(jobdeleteData(selectedUserId?._id));
    onCloseModal();
  };
  return (
    <div className="content-wrapper">
      {!onRequest && (
        <>
          <div className="container-fluid flex-grow-1 pt-4">
            <div className="d-flex align-item-center justify-content-center gap-3">
              {attendanceData.map((record, index) => (
                <div key={index} className="cardmorningd">
                  <div className="totalnumber_card">
                    <div className="left_conetent">
                      <div className="view-iocn">{record.imgSrc}</div>
                    </div>
                    <div className="right_req">
                      <h4>{record.status}</h4>
                      <div className="min_flex_req">
                        <h1>{record.count}</h1>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="container-fluid view-requestb">
              <div className="table-responsive">
                {pageLoading ? (
                  <SpinnerLoader />
                ) : (
                  <table className="table table-bordered align-middle">
                    <thead>
                      <tr>
                        {[
                          { name: "Job Type", icon: null },
                          { name: "Job Location", icon: null },
                          { name: "Experienced", icon: null },
                          { name: "Job Role", icon: null },
                          { name: "Status", icon: greydrop }, // Add the icon for Status
                          { name: "Action", icon: null },
                        ].map((column, index) => (
                          <th key={index}>
                            {column.name}
                            {column.icon && (
                              <img
                                src={column.icon}
                                alt={`${column.name} dropdown icon`}
                                className="header-dropdown-icon"
                                onClick={() =>
                                  console.log(
                                    `Dropdown clicked for ${column.name}`
                                  )
                                }
                                style={{ marginLeft: "8px", cursor: "pointer" }}
                              />
                            )}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {allJobList?.data?.jobs?.map((job, index) => (
                        <tr key={index}>
                          <td>{job.title}</td>
                          <td>{job.store?.address}</td>
                          <td>{job.experience}</td>
                          <td>{job.role}</td>
                          <td>
                            <span
                              className={`badge badge-${job.status?.toLowerCase()}`}
                            >
                              {job.status}
                            </span>
                          </td>
                          <td>
                            <span className="d-flex align-item-center justify-content-center gap-3">
                              <button
                                className="btn-view"
                                onClick={() => OpenRequest(job._id)}
                              >
                                View
                              </button>
                              <button
                                style={{ padding: "15px 20px" }}
                                onClick={() => onOpenModal(job)}
                              >
                                Delete
                              </button>
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                <Pagination
                  listData={allJobList?.meta}
                  setPageData={setCurrentPage}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <>
        {open && (
          <Delete open={open} onClose={onCloseModal} onConfirm={handleDelete} />
        )}
      </>

      {onRequest && <ViewJob />}
    </div>
  );
};

export default Jobs;

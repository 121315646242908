import React, { useState } from "react";
import "./addstore.css";
import { VscClose } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { addSettingStore } from "../../redux/Action";

const AddStoreNew = ({ setOpenInviteMember, onClose }) => {
  const dispatch = useDispatch();
  const allStoreLists = useSelector((state) => state.main.allStoreLists);

  const fields = [
    { label: "Store Name", type: "text", name: "name" },
    {
      label: "Company Name",
      type: "select",
      name: "company_id",
      options: allStoreLists?.data, // Add your actual company options
    },
    { label: "Store Address", type: "text", name: "address" },
  ];

  // State to manage form input values for the Store component
  const [formData, setFormData] = useState({
    name: "",
    company_id: "",
    address: "",
  });

  // Handle form changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const clickSubmitHandler = () => {
    dispatch(addSettingStore(formData, setOpenInviteMember));
  };

  return (
    <>
      <div className="addstore">
        <div className="d-flex justify-content-between">
          <h2>Add New Store</h2>
          <VscClose size={20} onClick={onClose} />
        </div>
      </div>
      <div className="border-bottom"></div>
      <div
        className="d-flex justify-content-between gap-1 content_common"
        style={{ borderBottom: "2px solid #2C357D2E" }}
      >
        <div className="label_name">
          {fields?.map((field) => (
            <label key={field.name} htmlFor={field.name}>
              {field.label}
            </label>
          ))}
        </div>

        <div className="input_group mx-auto">
          {fields?.map((field) => (
            <div key={field.name} className="form-group">
              {field.type === "select" ? (
                <select
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  className="form-control"
                >
                  <option value="">Select {field.label}</option>
                  {field?.options?.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.company_name}
                    </option>
                  ))}
                </select>
              ) : field.type === "file" ? (
                <input
                  type="file"
                  name={field.name}
                  accept="image/*"
                  onChange={handleChange}
                  className="form-control"
                />
              ) : (
                <input
                  className="form-control"
                  type={field.type}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  placeholder={`Enter ${field.label}`}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="border-bottom"></div>
      <div className="btnblue px-4 pb-5 mt-1">
        <button onClick={clickSubmitHandler}>Submit</button>
      </div>
    </>
  );
};

export default AddStoreNew;

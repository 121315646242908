import React, { useState } from "react";
import "./customSelector.css";
import dropnewicon from "../../../assets/img/dropnewicon.svg";

const SelectorReffered = ({
  label,
  options,
  name,
  value,
  onChange,
  LabelFontSize,
  SelectorFontSize,
  labelColor = "#2C357D80",
  starColor = "red",
  showStar,
  error,
  width,
  borderColor,
  disabled,
  allJobDetailList,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (optionValue) => {
    onChange({ target: { name, value: optionValue } });
    setIsOpen(false);
  };

  const labelStyle = {
    fontSize: LabelFontSize,
    color: labelColor,
  };

  const selectStyle = {
    fontSize: SelectorFontSize,
    borderBottom: `1px solid ${borderColor}`,
  };

  return (
    <div className="selector_wrapper" style={{ width: width || "100%" }}>
      <label className="selector_label" style={labelStyle}>
        {label} {showStar && <span style={{ color: starColor }}> *</span>}
      </label>
      <div
        className="custom_selector"
        style={selectStyle}
        onClick={handleToggleDropdown}
      >
        {value
          ? options?.find((option) => option?.value === value)?.label
          : "Select an option"}
        <span className={`dropdown_icon ${isOpen ? "open" : ""}`}>
          <img
            src={dropnewicon}
            alt=""
            className={`dropdown_icon ${isOpen ? "open" : ""}`}
          />
        </span>
      </div>

      {isOpen && (
        <div className="custom_dropdown">
          <div className="section_header">
            Referred Candidates (
            {allJobDetailList?.data?.referJobApplicants?.length})
          </div>
          {options
            ?.filter((option) => option.category === "referred")
            ?.map((option) => (
              <div
                key={option.value}
                className="custom_option"
                onClick={() => handleOptionClick(option.value)}
              >
                <span>{option.label}</span>
                <span>{option.username}</span>
              </div>
            ))}
          <div className="divider"></div>
          <div className="section_header">
            Other Candidates (
            {allJobDetailList?.data?.nonReferJobApplicants?.length})
          </div>
          {options
            ?.filter((option) => option.category === "other")
            ?.map((option) => (
              <div
                key={option.value}
                className="custom_option"
                onClick={() => handleOptionClick(option.value)}
              >
                {option.label}
              </div>
            ))}
        </div>
      )}
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default SelectorReffered;
